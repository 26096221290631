import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About me" />
      <h1>About me</h1>
      <p>On my day job I am focusing on Full Stack JavaScript development, Application Architecture and Cloud.</p>
      <p>I started this blog as a place where I could keep the record of what I'm doing on my hobby projects. Also starting a blog is a great way to practice my writing skills. And there's also a chance that things I'll write about will be relevant to someone on the internet.</p>
      <hr/>
      <Bio />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
